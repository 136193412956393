import { useTranslation } from "react-i18next";
import syncIcon from "../Assets/gfx/icons/sync-orange.svg";
import mailIcon from "../Assets/gfx/icons/mail.svg";
import {
  ProfileType,
  RiderModel,
  RiderStatus,
} from "../Api/Models/rider.model";
import Car from "../Assets/gfx/icons/vehicles/car.png";
import Motor from "../Assets/gfx/icons/vehicles/motorcycle.png";
import Police from "../Assets/gfx/icons/vehicles/police.png";
import FullTime from "../Assets/gfx/icons/8666609_user_icon.png";

interface ProfileDetailsProps {
  rider: RiderModel;
  gearExchange: boolean;
  hasGear?: boolean;
  gearMissing: number | null;
}

const ProfileDetails: React.FunctionComponent<ProfileDetailsProps> = ({
  rider,
  hasGear,
  gearExchange,
  gearMissing,
}) => {
  const { t } = useTranslation();

  const getVehicleImage = () => {
    if (rider.vehicle === 3) {
      return Car;
    }

    if (rider.vehicle === 2) {
      return Motor;
    }

    /*if (rider.vehicle === 1) {
      return Police;
    }*/
  };

  const getStatusIndicator = () => {
    if (
      rider.status === RiderStatus.INACTIVE ||
      rider.status === RiderStatus.CLEARANCE
    ) {
      return (
        <svg
          fill="none"
          stroke="red"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="24px"
          className="inline"
          style={{ marginLeft: "3px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      );
    }
    console.log(
      rider.profileType === ProfileType.TPATROLLER,
      rider.profileType,
      ProfileType.TPATROLLER
    );

    if (rider.status === RiderStatus.ACITVE) {
      return (
        <svg
          fill="none"
          stroke="green"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="24px"
          className="inline"
          style={{ marginLeft: "3px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      );
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="20px"
        className="inline"
        style={{ marginLeft: "3px" }}
      >
        <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
      </svg>
    );
  };

  return (
    <div className="gap-4px nav-card flex-col w-80 mb-4 background-transparent-white">
      <p className="text-small">
        {t("Rider ID")}: {rider.riderId} {getStatusIndicator()}
      </p>
      <span className="font-bold font-14">
        {rider.name}
        {rider.vehicle && (
          <img
            src={getVehicleImage()}
            width="30px"
            alt="Vehicle"
            style={{ display: "inline", marginLeft: "10px" }}
          />
        )}
        {rider.profileType === ProfileType.TPATROLLER && (
          <img
            src={Police}
            width="25px"
            alt="Police"
            style={{ display: "inline", marginLeft: "5px" }}
          />
        )}
        {rider.profileType === ProfileType.FULL_TIME && (
          <img
            src={FullTime}
            width="25px"
            alt="Police"
            style={{ display: "inline", marginLeft: "5px" }}
          />
        )}
      </span>
      <span className="font-10">{rider.tpl_name}</span>
      <span className="font-10">{rider.email}</span>
      {rider.status === RiderStatus.ACITVE ? (
        <>
          {gearExchange ? (
            <span className="font-12 text-orange flex gap-4px items-center">
              <img src={syncIcon} className="size-12" alt="sync icon" />{" "}
              {t("Gear Exchange Required!")}
            </span>
          ) : null}
          {gearMissing ? (
            <span className="font-12 text-blue flex gap-4px items-center">
              <img src={mailIcon} className="size-12" alt="mail icon" />{" "}
              {t("Missing item(s)")}
            </span>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default ProfileDetails;
