import { useTranslation } from "react-i18next";
import { useLoading } from "../../Context/LoadingProvider";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { riderService } from "../../Api/Services/RiderService";
import ImageInput from "../../Components/ImageInput";

interface ChangeImageProps {}

const ChangeImage: React.FunctionComponent<ChangeImageProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const { setPageTitle, rider, setRider } =
    useOutletContext<RiderProfileLayoutContextInterface>();
  useEffect(() => {
    setPageTitle(t("Change Image"));
  }, [t, setPageTitle]);

  const [images, setImages] = useState<File[]>([]);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    startLoading("update-rider-profile");
    riderService
      .update(rider.riderId, true, null, rider.vehicle, images[0] ?? null)
      .then((rider) => {
        setRider(rider);
        navigate(`/rider/${rider.riderId}/profile`);
      })
      .finally(() => {
        finnishLoading("update-rider-profile");
      });
  };
  return (
    <>
      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <ImageInput
          images={images}
          setImages={setImages}
          title={t("Take Rider Picture")}
          multiple={false}
          required={true}
        />
        <div className="flex flex-col items-center">
          <button type="submit" className="button button--green mb-4">
            {t("Save")}
          </button>
          <Link to={`/rider/${rider.riderId}/profile`} className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </form>
    </>
  );
};

export default ChangeImage;
