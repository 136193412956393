import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Importáljuk a useNavigate-t
import { OrderModel, OrderStatus } from "../../../Api/Models/order.model";
import { LaravelValidationError } from "../../../Api/request";
import { orderService } from "../../../Api/Services/OrderService";
import { stockService } from "../../../Api/Services/StockService";
import ImageInput from "../../../Components/ImageInput";
import { useInfoModal } from "../../../Context/InfoModalProvider";
import { useLoading } from "../../../Context/LoadingProvider";

interface ShowStockOrderProps {}

const ShowStockOrder: React.FunctionComponent<ShowStockOrderProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  let { orderId } = useParams();
  const navigate = useNavigate(); // useNavigate használata
  const location = useLocation(); // Hozzáférünk a location state-hez
  const { selectedFilter } = location.state || {}; // selectedFilter elérhető itt

  const accidentQuantityRef = useRef<HTMLInputElement | null>(null);
  const accidentNoteRef = useRef<HTMLTextAreaElement | null>(null);

  const { showInfo } = useInfoModal();
  const [orderData, setOrderData] = useState<OrderModel>();
  const [report, setReport] = useState<boolean>(false);
  const { t } = useTranslation();
  const inboundFromRef = useRef<HTMLFormElement | null>(null);

  const [images, setImages] = useState<File[]>([]);

  useEffect(() => {
    if (!orderData) {
      if (!orderId) {
        navigate("/stock/orders-list");
        return;
      }
      startLoading("get-order");
      orderService
          .getOrder(parseInt(orderId))
          .then((orderDataModel) => {
            setOrderData(orderDataModel);
          })
          .catch((e) => {
            navigate("/stock/orders-list");
          })
          .finally(() => {
            finnishLoading("get-order");
          });
    }
    //eslint-disable-next-line
  }, []);

  const handleAccidentSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
        !orderData?.products[0] ||
        !accidentQuantityRef.current?.value ||
        images.length <= 0
    ) {
      return;
    }

    setReport(false);

    startLoading("report-order-accident");
    stockService
        .report(
            orderData.products[0].id,
            parseInt(accidentQuantityRef.current.value),
            accidentNoteRef.current?.value ?? "",
            images
        )
        .then((newOrderData) => {
          setOrderData(newOrderData);
          showInfo(t("Report successfully saved. Please inbound the order now!"));
        })
        .catch((error: LaravelValidationError) => {
          if (error.errors?.errorMessage) {
            showInfo(error.errors?.errorMessage);
          }
        })
        .finally(() => {
          finnishLoading("report-order-accident");
        });
  };

  const handleOrderDispatched = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (orderData) {
      startLoading("set-order-dispatched");
      stockService
          .dispatch(orderData.id, images)
          .then((newOrderData) => {
            setOrderData(newOrderData);
            showInfo(t("Order successfully inbounded!"));
            navigate("/stock/orders/list", {
              state: { selectedFilter }, // selectedFilter visszaadása navigáláskor
            });
          })
          .catch((error: LaravelValidationError) => {
            if (error.errors?.errorMessage) {
              showInfo(error.errors?.errorMessage);
            }
          })
          .finally(() => {
            finnishLoading("set-order-dispatched");
          });
    }
  };

  if (!orderData) {
    return (
        <div className="view-container background-3">
          <div className="view-content" style={{ paddingTop: "24px" }}>
            <div className="heading text-center">{t("Product inbound")}</div>
          </div>
        </div>
    );
  }

  return (
      <div className="view-container background-2">
        <div className="view-content" style={{ paddingTop: "24px" }}>
          {report && (
              <div
                  className={"info-modal modal" + (!report ? "hidden" : "")}
                  tabIndex={-1}
                  role="dialog"
              >
                <div className="modal__dialog">
                  <div className="modal__header">
                    <div className="modal__heading">{t("Report an accident")}</div>
                    <div className="modal__close" onClick={() => setReport(false)}>
                      ✖
                    </div>
                  </div>
                  <form className="modal__body" onSubmit={handleAccidentSubmit}>
                    <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "start",
                          marginBottom: "10px",
                        }}
                    >
                      <img
                          src={orderData.products[0].image ?? ""}
                          alt="Product"
                          style={{ height: "75px" }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <strong>
                          <span>{orderData.products[0]?.name ?? ""}</span>
                        </strong>
                        <span>
                      {t("Ordered quantity:")}{" "}
                          {orderData.products[0].quantity ?? ""}
                    </span>
                      </div>
                    </div>

                    <label>{t("Received quantity")}</label>
                    <input
                        type="number"
                        step={1}
                        min={0}
                        max={9999999}
                        ref={accidentQuantityRef}
                        placeholder={t("Received quantity") ?? ""}
                        className="input-field bg-lightgray mb-2"
                        required
                    />
                    <label>{t("Note")}</label>
                    <textarea
                        ref={accidentNoteRef}
                        placeholder={t("Note") ?? ""}
                        className="bg-lightgray mb-6"
                        required
                    ></textarea>
                    <div className="flex justify-center mb-8 mt-5">
                      <button type="submit" className="button button--green">
                        {t("Save")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
          )}

          <div className="heading text-center">{t("Product inbound")}</div>
          <div className="content-card mb-8">
            <table className="info-table">
              <tbody>
              <tr>
                <td colSpan={2}>
                  <img
                      src={orderData.products[0].image ?? ""}
                      alt="Product"
                      style={{ height: "75px", margin: "auto" }}
                  />
                </td>
              </tr>
              <tr>
                <th>{t("Distribution center")}:</th>
                <td>{orderData.distributionCenter}</td>
              </tr>
              <tr>
                <th>{t("Status")}:</th>
                <td>{OrderStatus[orderData.status].toString()}</td>
              </tr>
              <tr>
                <th>{t("Order ID")}:</th>
                <td>{orderData.number}</td>
              </tr>
              <tr>
                <th>{t("Order date")}:</th>
                <td>{orderData.orderDate}</td>
              </tr>
              <tr>
                <th>{t("Product")}:</th>
                <td>
                  {orderData.products
                      .map((product) => product.name)
                      .join(", ")}
                </td>
              </tr>
              <tr>
                <th>{t("Quantity")}:</th>
                <td>
                  {orderData.products[0]?.quantity ? (
                      <>
                        {orderData.products[0].quantity}
                        &nbsp;{t("Pcs.")}
                      </>
                  ) : null}
                  {orderData.products[0].receivedQuantity ? (
                      <>
                        &nbsp;&nbsp;({t("Received")}:&nbsp;
                        {orderData.products[0].receivedQuantity}
                        &nbsp;{t("Pcs.")})
                      </>
                  ) : null}
                </td>
              </tr>
              <tr>
                <th>{t("Note")}:</th>
                <td>{orderData.note}</td>
              </tr>
              <tr>
                <th>{t("Inbound date")}:</th>
                <td>{orderData.inboundDate}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-center gap-3 mb-4 items-center">
            {orderData.status !== OrderStatus.INBOUNDED &&
                orderData.status !== OrderStatus.DECLINED &&
                orderData.status !== OrderStatus.PENDING && (
                    <form
                        onSubmit={handleOrderDispatched}
                        className="flex flex-col items-center"
                        ref={inboundFromRef}
                    >
                      <ImageInput images={images} setImages={setImages} />

                      <div style={{ display: "flex", gap: "20px" }}>
                        <button
                            type="submit"
                            className="button-2 button-2--approved"
                        >
                          <div className="button-2__icon">
                            <i className="icon-approve"></i>
                          </div>
                          <span className="button-2__text">{t("Inbound")}</span>
                        </button>

                        <button
                            type="button"
                            disabled={orderData.hasAccident}
                            className={
                                "button-2 " +
                                (orderData.hasAccident
                                    ? "button-2--disabled"
                                    : "button-2--declined")
                            }
                            onClick={() => {
                              if (
                                  inboundFromRef.current &&
                                  inboundFromRef.current.reportValidity() &&
                                  !orderData.hasAccident
                              ) {
                                setReport(true);
                              }
                            }}
                        >
                          <div className="button-2__icon">
                            <i className="icon-refuse"></i>
                          </div>
                          <span className="button-2__text">{t("Report")}</span>
                        </button>
                      </div>
                    </form>
                )}
          </div>
          <div className="flex justify-center">
            <button
                onClick={() =>
                    navigate("/stock/orders/list", {
                      state: {selectedFilter},  // Visszaadod a selectedFilter-t a visszalépéskor
                    })
                }
                className="button"
            >
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </button>
          </div>
        </div>
      </div>
  );
};

export default ShowStockOrder;
