import { GoalModel } from "../Models/goal.model";
import { OrderModel } from "../Models/order.model";
import { ProductModel } from "../Models/product.model";
import request, { Method } from "../request";

class OrderService {
  async getGoal() {
    return request<GoalModel>(`/orders/goal`, Method.GET);
  }

  async getProductVatiations(productId: number) {
    return request<ProductModel>(
      `/orders/product-variations/${productId}`,
      Method.GET
    );
  }

  async getOrder(orderId: number) {
    return request<OrderModel>(`/orders/${orderId}`, Method.GET);
  }

  async listOrders(page = 0) {
    return request<OrderModel[]>(`/orders/list?page=${page}`, Method.GET,{
      data: {
        page: page,
      },
    });
  }

  async getOrderDataFromQr(data: string) {
    return request<OrderModel>(`/qr/order`, Method.POST, {
      data: {
        data: data,
      },
    });
  }

  async dispatch(orderId: number) {
    return request<OrderModel>(`/orders/dispatch`, Method.POST, {
      data: {
        order_id: orderId,
      },
    });
  }

  async undoDispatch(orderId: number) {
    return request<OrderModel>(`/orders/undo-dispatch`, Method.POST, {
      data: {
        order_id: orderId,
      },
    });
  }

  async refuse(orderId: number) {
    return request<OrderModel>(`/orders/refuse`, Method.POST, {
      data: {
        order_id: orderId,
      },
    });
  }

  async undoRefuse(orderId: number) {
    return request<OrderModel>(`/orders/undo-refuse`, Method.POST, {
      data: {
        order_id: orderId,
      },
    });
  }

  async exchangeOrderItem(formData: FormData) {
    return request<OrderModel>(`/orders/exchange-order-item`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async orderItemChangeSize(formData: FormData) {
    return request<OrderModel>(`/orders/order-item-change-size`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const orderService = new OrderService();
