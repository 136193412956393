import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../../Context/LoadingProvider";
import { useUser } from "../../Context/UserProvider";
import OrderListIcon from "../../Assets/gfx/icons/nav/order-list.svg";
import StockList from "../../Assets/gfx/icons/nav/stock.png";
import Sanitization from "../../Assets/gfx/icons/nav/sanitization.svg";
import { useTranslation } from "react-i18next";
import { weeklyStockCheckService } from "../../Api/Services/WeeklyStockCheckService";

interface FunctionsMenuProps {}

const FunctionsMenu: React.FunctionComponent<FunctionsMenuProps> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { logout, user } = useUser();
  //const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const { t } = useTranslation();

  const [stockCheckComplete, setStockCheckComplete] = useState<boolean>(false);

  function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result
      ) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== "granted") {
        console.log("We weren't granted permission.");
      }
    });
  }

  const fetchWeeklyComplete = async () => {
    startLoading("fetch-weekly-stock-check-complete");
    weeklyStockCheckService
      .thisWeekComplete()
      .then((result) => {
        setStockCheckComplete(result.complete);
      })
      .finally(() => finnishLoading("fetch-weekly-stock-check-complete"));
  };

  useEffect(() => {
    askPermission();
    fetchWeeklyComplete();
    //eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    startLoading("logout");
    logout()
      .then(() => {
        navigate("/");
      })
      .finally(() => {
        finnishLoading("logout");
      });
  };

  /*const closeQrReaderModal = () => {
    setShowQrReader(false);
  };*/

  return (
    <div className="view-container background-3">
      <div className="view-content">
        {/*
        <LanguageSelector />

        <QrReaderModal show={showQrReader} close={closeQrReaderModal} />
        <div className="flex justify-center">
          <div className="scan-qr bg-white rounded-xl mb-8">
            <div
              className="scan-qr__image"
              onClick={() => setShowQrReader(true)}
            >
              <div className="scan-qr__corners">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img src={QrCodeImage} alt="Qr reader" />
            </div>
            <div className="scan-qr__text">{t("scan qr")}</div>
          </div>
        </div>
              */}
        <div className="limited-width-content">
          <div className="card-list">
            <Link
              to={stockCheckComplete ? "/rider/search" : "#"}
              className={
                "nav-card " + (!stockCheckComplete ? "nav-card_disabled" : "")
              }
            >
              <img
                src={OrderListIcon}
                className="nav-card__icon"
                alt="Rider search"
              />
              <div className="nav-card__title">{t("Rider Search")}</div>
            </Link>

            {/*
            <Link to="/orders/list" className="nav-card">
              <img
                src={OrderListIcon}
                className="nav-card__icon"
                alt="Order list"
              />
              <div className="nav-card__title">{t("Order list")}</div>
            </Link>

            <Link to="/return/list" className="nav-card">
              <img
                src={ReturnListIcon}
                className="nav-card__icon"
                alt="Return list"
              />
              <div className="nav-card__title">{t("Return list")}</div>
            </Link>

            <Link to="/warranty/list" className="nav-card">
              <img
                src={ExchangeListIcon}
                className="nav-card__icon"
                alt="Exchange list"
              />
              <div className="nav-card__title">{t("Exchange list")}</div>
            </Link>
            */}
            {!user?.permissions?.includes("app_user.view_only") ? (
              <>
                <Link to="/stock/list" className="nav-card">
                  <img
                    src={StockList}
                    className="nav-card__icon"
                    alt="Stock list"
                  />
                  <div className="nav-card__title">{t("Stock")}</div>
                </Link>

                {/*<Link to="/sanitization/list" className="nav-card">
                  <img
                    src={Sanitization}
                    className="nav-card__icon"
                    alt="Sanitizazion list"
                  />
                  <div className="nav-card__title">{t("Sanitization")}</div>
                </Link>*/}

                <Link
                  to={!stockCheckComplete ? "/stock/weekly-stock-check" : "#"}
                  className={
                    "nav-card " +
                    (stockCheckComplete ? "nav-card_disabled" : "")
                  }
                >
                  <img
                    src={Sanitization}
                    className="nav-card__icon"
                    alt="Stock check"
                  />
                  <div className="nav-card__title">
                    {t("Stock check")}
                    {!stockCheckComplete && (
                      <div className="status-bubble red d-inline-block ml-6"></div>
                    )}
                  </div>
                </Link>
              </>
            ) : null}

            {/*
            <Link to="/new-kit/list" className="nav-card">
              <img src={NewKit} className="nav-card__icon" alt="New Kit list" />
              <div className="nav-card__title">{t("New kit")}</div>
            </Link>
            */}

            <div className="text-center mt-12">
              <button
                type="submit"
                className="button button--orange button--small"
                onClick={handleLogout}
              >
                {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunctionsMenu;
