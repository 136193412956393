import { t } from "i18next";
import ImageInput from "../ImageInput";
import { useRef, useState } from "react";
import { ReturnCondition } from "../../Api/Models/return.model";

interface ProductStateProps {
  backButtonOnClick: CallableFunction;
  saveButtonOnClick: CallableFunction;
}

const ProductState: React.FunctionComponent<ProductStateProps> = ({
  backButtonOnClick,
  saveButtonOnClick,
}) => {
  const conditionRef = useRef<ReturnCondition>();
  const noteRef = useRef<HTMLTextAreaElement | null>(null);

  const [images, setImages] = useState<File[]>([]);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveButtonOnClick(
      conditionRef.current,
      images[0] ?? null,
      noteRef.current?.value
    );
  };

  const onConditionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && e.target.value) {
      conditionRef.current = +e.target.value;
    }
  };

  return (
    <form onSubmit={handleSave}>
      <p className="text-white text-center text-large">
        {t("Gear Owned by the Rider")}
      </p>
      <div className="flex gap-2 mb-2 flex-wrap justify-center">
        <div className="flex gap-4 w-full justify-center">
          <div>
            <input
              type="radio"
              id="manufacturing_fault"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.MANUFACTURING_FAULT}
              required
            />
            <label
              htmlFor="manufacturing_fault"
              className="w-full button--blue"
            >
              {t("Manufacturing faultiness")}
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="overused"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.OVERUSED}
              required
            />
            <label htmlFor="overused" className="w-full">
              {t("Overused")}
            </label>
          </div>
        </div>
        <div className="flex gap-4 mb-4 w-full justify-center">
          <div>
            <input
              type="radio"
              id="broken"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.BROKEN}
              required
            />
            <label htmlFor="broken" className="w-full">
              {t("Broken")}
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="stain"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.STAIN}
              required
            />
            <label htmlFor="stain" className="w-full">
              {t("Stain")}
            </label>
          </div>
        </div>
      </div>

      <ImageInput images={images} setImages={setImages} required={true} />

      <textarea
        className="mb-8"
        placeholder="Please write your note here!"
        ref={noteRef}
      ></textarea>

      <div className="flex justify-between">
        <button className="button" onClick={() => backButtonOnClick()}>
          <i className="icon-arrow-left button__icon button__icon--prefix"></i>
          {t("Back")}
        </button>
        <button type="submit" className="button button--green">
          {t("Save")}
        </button>
      </div>
    </form>
  );
};

export default ProductState;
