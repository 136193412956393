import { t } from "i18next";
import React from "react";
import { RiderModel, RiderProductModel } from "../../Api/Models/rider.model";
import exchangeIcon from "../../Assets/gfx/icons/sync.svg";
import { ReturnStatus } from "../../Api/Models/return.model";
import { useUser } from "../../Context/UserProvider";

interface GearsProps {
  gears: RiderProductModel[];
  setAssignNewGearItem: CallableFunction;
  rider: RiderModel;
}

const Gears: React.FunctionComponent<GearsProps> = ({
  gears,
  setAssignNewGearItem,
  rider,
}) => {
  const { user } = useUser();

  const getGearColor = (gear: RiderProductModel) => {
    if (gear.discontinued) {
      return "black";
    }

    if (gear.returnDate && gear.returnStatus !== ReturnStatus.MISSING) {
      return "grey";
    }

    if (!gear.exchangeDays || gear.returnStatus === ReturnStatus.MISSING) {
      return "red";
    }

    if (gear.exchangeDays <= 7) {
      return "orange";
    }

    return "green";
  };

  return (
    <div className="gap-4px nav-card mb-4 gears">
      {gears.length > 0 && (
        <div className="gears__kit-details">
          {gears.map((gear, index) => (
            <React.Fragment key={index}>
              <div className="gears__kit-details__item">
                <div>
                  <img src={gear.productImage} alt="package name" />
                  <p>
                    {gear.productName}
                    {gear.variationName ? ` - ${gear.variationName}` : ""}
                    <br />
                    {gear.returnDate ? (
                      <span className="font-8">
                        {t("Return Date")}:&nbsp;
                        <span className="text-orange">{gear.returnDate}</span>
                      </span>
                    ) : (
                      <span className="font-8">
                        {t("Assign Date")}:&nbsp;
                        <span className="text-orange">{gear.assignedAt}</span>
                      </span>
                    )}
                    <br />
                    {gear.discontinued ? (
                      <span className="font-8">{t("Discountinued")}</span>
                    ) : (gear?.exchangeDays ?? 0) > 0 ? (
                      <span className="font-8">
                        {t("Days until")}&nbsp;
                        <img
                          src={exchangeIcon}
                          alt="Exchange"
                          className="inline"
                          style={{ width: "8px" }}
                        />
                        <span className={"text-" + getGearColor(gear)}>
                          {gear.exchangeDays}
                        </span>
                      </span>
                    ) : null}
                  </p>
                </div>
                <div className={"status-bubble " + getGearColor(gear)}></div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {user?.permissions?.includes("app_user.assign_new_gear_item") &&
      !user?.permissions?.includes("app_user.view_only") ? (
        <div className="flex flex-col items-center w-full ">
          <button
            type="button"
            className={"button button--medium button--green"}
            onClick={() => setAssignNewGearItem(true)}
          >
            {t("Add historical gear data")}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Gears;
