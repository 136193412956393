import { /*Link,*/ useNavigate } from "react-router-dom";
import { useRef, FormEvent, useState } from "react";
import { useUser } from "../Context/UserProvider";
import { useLoading } from "../Context/LoadingProvider";
import { LaravelValidationError } from "../Api/request";
import Logo from "../Assets/gfx/boolanga_bi.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [validationErrors, setValidationErrors] = useState<Record<
    string,
    string[]
  > | null>(null);
  const navigate = useNavigate();
  const { login } = useUser();

  const { startLoading, finnishLoading } = useLoading();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (emailRef.current && passwordRef.current) {
      startLoading("login-submit");
      login(emailRef.current.value, passwordRef.current.value)
        .then(() => {
          navigate("/menu");
        })
        .catch((error: LaravelValidationError) => {
          setValidationErrors(error.errors);
        })
        .finally(() => {
          finnishLoading("login-submit");
        });
    }
  };

  return (
    <div className="view-container flex items-center justify-center background-2">
      <div className="view-content">
        <div className="heading heading--small text-center">
          {t("Please login to your account")}
        </div>

        <div className="brand-logo-container">
          <img className="brand-logo" src={Logo} alt="Wear your brand logo" />
        </div>
        <LanguageSelector />
        <form onSubmit={handleLogin} className="w-full px-4">
          <div className="mb-4">
            <label
              className={
                "label-with-input" + (validationErrors?.email ? " error" : "")
              }
            >
              <div className="label-with-input__text">{t("Email")}</div>
              <input
                type="email"
                placeholder="Email"
                required
                ref={emailRef}
                autoComplete="email"
              />
            </label>

            {validationErrors?.email && (
              <div className="input-error-text">
                {validationErrors?.email?.map((x) => (
                  <>
                    <br />
                    <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                  </>
                ))}
              </div>
            )}
          </div>

          <div className="mb-10">
            <label
              className={
                "label-with-input" +
                (validationErrors?.password ? " error" : "")
              }
            >
              <div className="label-with-input__text">{t("Password")}</div>
              <input
                type="password"
                placeholder="Password"
                required
                ref={passwordRef}
                autoComplete="current-password"
              />
            </label>

            {validationErrors?.password && (
              <div className="input-error-text">
                {validationErrors?.password?.map((x) => (
                  <>
                    <br />
                    <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                  </>
                ))}
              </div>
            )}
          </div>
          {/*
          <div className="mb-20 text-center">
            <Link to="/forgot-password" className="text-white text-small">
              Forgot password?
            </Link>
                </div>*/}

          <div className="text-center">
            <button type="submit" className="button button--orange button--big">
              {t("Login")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
