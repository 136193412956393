import { OrderModel } from "../Models/order.model";
import { StockModel } from "../Models/stock.model";
import request, { Method } from "../request";

export interface OrderDataInterface {
  product_id: number;
  product_variation_id: number | null;
  quantity: number;
  checked: boolean;
}

class StockService {
  async listStocks() {
    return request<StockModel[]>(`/stock/list`, Method.GET);
  }

  async createOrder(products: OrderDataInterface[], note: string | null) {
    return request(`/stock/order`, Method.POST, {
      data: {
        products: products,
        note: note,
      },
    });
  }

  async listStockOrders(page = 0, filter = -1) {
    return request<OrderModel[]>(`/stock/orders/list?page=${page}&filter=${filter}`, Method.GET);
  }

  async dispatch(orderId: number, images: File[]) {
    return request<OrderModel>(`/stock/orders/dispatch`, Method.POST, {
      data: {
        order_id: orderId,
        images: images,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async report(
    orderItemId: number,
    receivedQuantity: number,
    note: string,
    images: File[]
  ) {
    return request<OrderModel>(`/stock/orders/report`, Method.POST, {
      data: {
        order_item_id: orderItemId,
        received_quantity: receivedQuantity,
        note: note,
        images: images,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async stockCorrectionRequest(
    productId: number,
    productVariationId: number | null,
    quantity: number,
    message: string
  ) {
    return request<OrderModel>(`/stock/correction-request`, Method.POST, {
      data: {
        product_id: productId,
        product_variation_id: productVariationId,
        quantity: quantity,
        message: message,
      },
    });
  }
}

export const stockService = new StockService();
