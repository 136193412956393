import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useLoading } from "../../Context/LoadingProvider";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { WarrantyModel } from "../../Api/Models/warranty.model";
import { RiderProductModel } from "../../Api/Models/rider.model";
import { ReturnCondition } from "../../Api/Models/return.model";
import ProductState from "./ProductState";
import { warrantyService } from "../../Api/Services/WarrantyService";
import { RiderProfileLayoutContextInterface } from "../../Pages/Rider/RiderProfileLayout";
import { LaravelValidationError } from "../../Api/request";
import ImageInput from "../ImageInput";

interface ExchangeItemProps {}

interface IncidentData {
  condition: ReturnCondition;
  note?: string | null;
  image: File | null;
}

const ExchangeItem: React.FunctionComponent<ExchangeItemProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  const { state } = useLocation();
  const navigate = useNavigate();
  let { riderId, riderProductId } = useParams();
  const { showInfo } = useInfoModal();
  const { t } = useTranslation();
  const [reportIncident, setReportIncident] = useState<boolean>(false);
  const [incidentData, setIncidentData] = useState<IncidentData | null>(null);
  const [warrantyData] = useState<WarrantyModel>(state?.warrantyData);

  const [riderProduct] = useState<RiderProductModel>(state?.riderProduct);
  const [images, setImages] = useState<File[]>([]);

  const { rider } = useOutletContext<RiderProfileLayoutContextInterface>();

  const variationRef = useRef<HTMLSelectElement | null>(null);

  useEffect(() => {
    if (!warrantyData || !riderProductId) {
      navigate(`/rider/${riderId}/profile`);
    }

    //eslint-disable-next-line
  }, []);

  const handleIncidentSave = (
    condition: ReturnCondition,
    image: File,
    note: string | null
  ) => {
    setIncidentData({
      condition: condition,
      note: note,
      image: image,
    });

    startLoading("warranty-save-condition");

    warrantyService
      .saveCondition(warrantyData.id, condition, note, image)
      .then(() => {
        showInfo(t("Accident successfully saved!"));
      })
      .finally(() => {
        finnishLoading("warranty-save-condition");
      });
    setReportIncident(false);
  };
  const handleIncidentClose = () => {
    setReportIncident(false);
  };

  const approve = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (warrantyData.incidentRequired && !incidentData) {
      showInfo(t("Please report an accident to exchange the product!"));
      return;
    }

    if (!riderProductId || images.length < 1) {
      return;
    }
    let variationId = null;
    if (variationRef.current) {
      variationId = parseInt(variationRef.current.value);
    }

    startLoading("dispatch-warranty");
    warrantyService
      .dispatch(
        warrantyData.id,
        parseInt(riderProductId),
        images[0],
        variationId
      )
      .then(() => {
        showInfo(t("Product successfully exchanged!"));
        navigate(`/rider/${rider.riderId}/profile`, {
          state: { tab: "exchange" },
        });
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("dispatch-warranty");
      });
  };

  const decline = () => {
    startLoading("decline-warranty");
    if (!riderProductId) {
      return;
    }
    warrantyService
      .refuse(warrantyData.id, parseInt(riderProductId))
      .then(() => {
        showInfo(t("Product exchange declined!"));
        navigate(`/rider/${rider.riderId}/profile`, {
          state: { tab: "exchange" },
        });
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("decline-warranty");
      });
  };

  if (!riderProduct || !warrantyData) {
    return <></>;
  }

  if (reportIncident) {
    return (
      <ProductState
        saveButtonOnClick={handleIncidentSave}
        backButtonOnClick={handleIncidentClose}
      />
    );
  }

  return (
    <form className="flex flex-col gap-4px" onSubmit={approve}>
      <div className="exchange-list__details nav-card flex-col gap-4px">
        <div className="gap-4px flex justify-between items-center w-full mb-2">
          <div className="flex">
            <img src={riderProduct.productImage} alt="package name" />
            <div>
              <p className="font-12 font-bold">{warrantyData.productName}</p>
              <span className="block font-8">
                {t("Gear Assigned at")}:&nbsp;{riderProduct.assignedAt}
              </span>
            </div>
          </div>
          <div
            className={
              "status-bubble " +
              (!riderProduct?.exchangeAvailableAt ? "bad" : "good")
            }
          ></div>
        </div>
        <div className="grey-divider w-full"></div>
        <div className="w-full mt-2">
          {!riderProduct?.exchangeAvailableAt ? (
            <p className="font-8 font-bold">{t("Exchange Required!")}</p>
          ) : (
            <p className="font-8 font-bold">
              {t("Exchange Eligible at")}:&nbsp;
              <span className="text-blue">
                {riderProduct.exchangeAvailableAt}
              </span>
            </p>
          )}
        </div>
        {warrantyData.availableVariations.length > 0 &&
        warrantyData.defaultVariation ? (
          <select
            defaultValue={warrantyData.defaultVariation}
            ref={variationRef}
            className="select-outline"
          >
            {warrantyData.availableVariations.map((variation) => (
              <option key={variation.id} value={variation.id}>
                {variation.name}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      <div className="mt-4 flex justify-around w-full px-4 flex-wrap gap-3 mb-3">
        <button
          type="submit"
          disabled={
            !warrantyData.exchangeAllowed ||
            (warrantyData.incidentRequired && !incidentData)
          }
          className={
            "button button--small font-12 py-2 px-6 " +
            (warrantyData.exchangeAllowed &&
            ((warrantyData.incidentRequired && !!incidentData) ||
              !warrantyData.incidentRequired)
              ? "button--green"
              : "button--grey")
          }
        >
          {t("Approve")}
        </button>
        <button
          type="button"
          disabled={!!incidentData}
          className={
            "button button--small font-12 py-2 px-6 " +
            (!!incidentData ? "button--grey" : "")
          }
          onClick={() => decline()}
        >
          {t("Decline")}
        </button>

        {warrantyData.incidentRequired && (
          <button
            type="button"
            className={
              "button button--small font-12 py-2 px-6 button--blue xs-w-full " +
              (incidentData ? "button--grey" : "")
            }
            onClick={() => {
              //if (!incidentData) {
              setReportIncident(true);
              //}
            }}
          >
            <i className="icon-exclamation button__icon-white button__icon--prefix"></i>
            {t("Report an accident")}
          </button>
        )}
      </div>
      <p className="text-center text-white">
        {t("Please take an image of the exchanged item!")}
      </p>
      <ImageInput images={images} setImages={setImages} />

      <div className="flex flex-col items-center mt-6">
        <button
          onClick={() => {
            navigate(`/rider/${rider.riderId}/profile`, {
              state: { tab: "exchange" },
            });
          }}
          className="button"
        >
          <i className="icon-arrow-left button__icon button__icon--prefix"></i>
          {t("Back")}
        </button>
      </div>
    </form>
  );
};

export default ExchangeItem;
