import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SanitizationModel,
  SanitizationStepModel,
  StepImages,
} from "../../Api/Models/sanitization.model";
import { LaravelValidationError } from "../../Api/request";
import { sanitizationService } from "../../Api/Services/SanitizationService";
import ImageInput from "../../Components/ImageInput";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import ThrowAway from "./ThrowAway";

interface ShowSanitizationProps {
  sanitization: SanitizationModel;
  setCurrentSanitizazion: CallableFunction;
}

const ShowSanitization: React.FunctionComponent<ShowSanitizationProps> = ({
  sanitization,
  setCurrentSanitizazion,
}) => {
  const { startLoading, finnishLoading } = useLoading();
  const [steps, setSteps] = useState<SanitizationStepModel[]>([]);
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [stepImages, setStepImages] = useState<StepImages[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);

  const fromRef = useRef<HTMLFormElement | null>(null);
  const [images, setImages] = useState<File[]>([]);

  const { showInfo } = useInfoModal();

  useEffect(() => {
    startLoading("get-sanitization-steps");

    sanitizationService
      .getSteps(sanitization.id)
      .then((steps) => {
        setSteps(steps);
      })
      .finally(() => {
        finnishLoading("get-sanitization-steps");
      });
    //eslint-disable-next-line
  }, []);

  const nextStep = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (images.length > 0) {
      const imagesState = stepImages;

      imagesState.push({
        step: currentStep,
        images: images,
      });
      setStepImages(imagesState);
      setImages([]);
    }

    fromRef.current?.reset();
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      startLoading("finnish-sanitization");

      sanitizationService
        .finnishSanitization(sanitization.id, stepImages)
        .then(() => {
          showInfo(t("Sanitization finnished!"));
          setCurrentSanitizazion(null);
        })
        .catch((error: LaravelValidationError) => {
          if (error.errors?.errorMessage) {
            showInfo(error.errors?.errorMessage);
          }
        })
        .finally(() => {
          finnishLoading("finnish-sanitization");
        });
    }
  };

  const cancelDeleting = () => {
    setDeleting(false);
  };

  const deleteFinnished = () => {
    setDeleting(false);
    setCurrentSanitizazion(null);
  };

  if (deleting) {
    return (
      <ThrowAway
        sanitizationId={sanitization.id}
        cancel={cancelDeleting}
        deleteFinnished={deleteFinnished}
      />
    );
  }

  return (
    <div className="view-container background-2">
      <form className="view-content" onSubmit={nextStep} ref={fromRef}>
        <div className="heading text-center">{t("Sanitization")}</div>

        <div className="flex justify-center mb-4">
          <div className="inline-flex">
            <div className="card">
              <div className="flex justify-between gap-3">
                <div className="flex items-center gap-2">
                  <img
                    src={sanitization.productImage ?? ""}
                    className="image image--w35"
                    alt="Product"
                  />
                  <div className="card__product-title">
                    {sanitization.productName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--larger-padding mb-10">
          <div className="text-center text-large text-gray mb-6">
            <strong>
              {t("Step")} {currentStep}/{steps.length}
            </strong>
          </div>
          {steps.length >= currentStep && (
            <div className="text-center text-large mb-6">
              <strong>{steps[currentStep - 1].text}</strong>
            </div>
          )}
          {steps.length >= currentStep &&
            (steps[currentStep - 1].photo ? (
              <ImageInput images={images} setImages={setImages} />
            ) : (
              <div className="flex justify-center">
                <label className="checkbox checkbox--large">
                  <input
                    type="checkbox"
                    name={"sanitization-step-" + currentStep}
                    id={"sanitization-step-" + currentStep}
                    required
                  />
                  <div className="checkbox__success-icon"></div>
                </label>
              </div>
            ))}
        </div>

        <div className="flex justify-center mb-3">
          <button type="submit" className="button">
            {currentStep === steps.length ? t("Finnish") : t("Next step")}
          </button>
        </div>
        <div className="flex justify-center mb-10">
          <button
            type="button"
            className="button text-red"
            onClick={() => setDeleting(true)}
          >
            Throw away
          </button>
        </div>

        <div className="flex justify-center">
          <span className="button" onClick={() => setCurrentSanitizazion(null)}>
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </span>
        </div>
      </form>
    </div>
  );
};

export default ShowSanitization;
