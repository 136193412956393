import { GoalModel } from "../Models/goal.model";
import { ReturnCondition } from "../Models/return.model";
import { WarrantyModel } from "../Models/warranty.model";
import request, { Method } from "../request";

class WarrantyService {
  async getGoal() {
    return request<GoalModel>(`/warranty/goal`, Method.GET);
  }

  async getWarranty(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/${warrantyId}`, Method.GET);
  }

  async listWarrantyReplacements() {
    return request<WarrantyModel[]>(`/warranty/list`, Method.GET);
  }

  async getWarrantyDataFromQr(data: string) {
    return request<WarrantyModel>(`/qr/warranty`, Method.POST, {
      data: {
        data: data,
      },
    });
  }

  async saveCondition(
    warrantyId: number,
    condition: ReturnCondition | null,
    note: string | null,
    image: File | null
  ) {
    return request<WarrantyModel>(
      `/warranty/save-condition/${warrantyId}`,
      Method.POST,
      {
        data: {
          condition_id: condition,
          note,
          image,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async dispatch(
    warrantyId: number,
    profileProductId: number,
    image: File,
    variationId: number | null
  ) {
    return request<WarrantyModel>(`/warranty/dispatch`, Method.POST, {
      data: {
        warranty_id: warrantyId,
        profile_product_id: profileProductId,
        variation_id: variationId,
        image: image,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async undoDispatch(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/undo-dispatch`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }

  async refuse(warrantyId: number, profileProductId: number) {
    return request<WarrantyModel>(`/warranty/refuse`, Method.POST, {
      data: {
        warranty_id: warrantyId,
        profile_product_id: profileProductId,
      },
    });
  }

  async undoRefuse(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/undo-refuse`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }
}

export const warrantyService = new WarrantyService();
