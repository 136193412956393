import { Link } from "react-router-dom";
import {t} from "i18next";

interface FloatingBackButtonProps {}

const FloatingBackButton: React.FunctionComponent<
  FloatingBackButtonProps
> = () => {
  return (
    <Link to="/menu" className="floating-back-button">
      <i className="icon-arrow-left button__icon button__icon--prefix"></i>
      &nbsp;<span className="text-white font-bold">{t("Home")}</span>
    </Link>
  );
};

export default FloatingBackButton;
