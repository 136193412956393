import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import TabView from "../../Components/TabView";
import { TabType } from "../../Types/Tab.type";
import Gears from "../../Components/Profile/Gears";
import Exchange from "../../Components/Profile/Exchange";
import Return from "../../Components/Profile/Return";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { RiderProductModel, RiderStatus } from "../../Api/Models/rider.model";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";
import IdVerification from "./IdVerification";
import AssignGear from "./AssignGear";
import VehicleSelect from "./VehicleSelect";
import { KitModel } from "../../Api/Models/product.model";
import AssignNewGearItem from "./AssignNewGearItem";
import { useUser } from "../../Context/UserProvider";

interface RiderProfileProps {}

const RiderProfile: React.FunctionComponent<RiderProfileProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const [allLoadingDone, setAllLoadingDone] = useState<boolean>(false);

  const {
    setPageTitle,
    rider,
    showTabsNavbar,
    setHasGear,
    setGearExchangeRequired,
    subTitle,
    setGearMissing,
  } = useOutletContext<RiderProfileLayoutContextInterface>();

  const [gears, setGears] = useState<RiderProductModel[]>([]);
  const [missingKitProducts, setMissingKitProducts] = useState<KitModel | null>(
    null
  );
  const [assignMissing, setAssignMissing] = useState<boolean>(false);
  const [assignNewGearItem, setAssignNewGearItem] = useState<boolean>(false);
  const { user } = useUser();

  const fetchGears = () => {
    startLoading("get-profile-gear");
    riderService
      .listGear(rider.riderId)
      .then((gear) => {
        setGears(gear);

        if(gear.length === 0 || !gear){
          fetchGearsSecondTry();
        }
      })
      .finally(() => {
        finnishLoading("get-profile-gear");
      });

    fetchMissingKitProducts();
  };

  const fetchGearsSecondTry = () => {
    startLoading("get-profile-gear");
    riderService
        .listGear(rider.riderId)
        .then((gear) => {
          setGears(gear);
        })
        .finally(() => {
          finnishLoading("get-profile-gear");
        });
  };

  const fetchMissingKitProducts = () => {
    startLoading("get-missing-produts");
    riderService
      .getMissingKitProducts(rider.riderId)
      .then((kit) => {
        setMissingKitProducts(kit);
      })
      .finally(() => {
        finnishLoading("get-missing-produts");
        setAllLoadingDone(true);
      });
  };

  const finnishKitAssign = () => {
    fetchGears();
    setAssignMissing(false);
  };

  useEffect(() => {
    fetchGears();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHasGear(gears.length > 0);
    setGearExchangeRequired(
      gears.filter((x) => !x.exchangeAvailableAt).length > 0
    );
    //eslint-disable-next-line
  }, [gears]);

  useEffect(() => {
    setPageTitle();
  }, [t, setPageTitle]);

  useEffect(() => {
    setGearMissing(missingKitProducts?.products?.length ?? null);
  }, [missingKitProducts, setGearMissing]);

  const skipedAllProducts = () => {
    setAssignMissing(false);
  };

  const tabs: TabType[] = [
    {
      element: (
        <Gears
          gears={gears}
          setAssignNewGearItem={setAssignNewGearItem}
          rider={rider}
        />
      ),
      key: "gear",
      title: t("Gear"),
    },
  ];

  if (!user?.permissions?.includes("app_user.view_only")) {
    tabs.push(
      ...[
        {
          element:
            rider.status === RiderStatus.INACTIVE ||
            rider.status === RiderStatus.CLEARANCE ? (
              <></>
            ) : (
              <Exchange
                gears={gears}
                rider={rider}
                updateGears={fetchGears}
                missingKitProducts={missingKitProducts}
                fetchMissingKitProducts={fetchMissingKitProducts}
              />
            ),
          key: "exchange",
          title: t("Action"),
        },
        {
          element: <Return rider={rider} updateGears={fetchGears} />,
          key: "return",
          title: t("Return"),
        },
      ]
    );
  }

  if (allLoadingDone && !user?.permissions?.includes("app_user.view_only") && !rider.idVerified) {
    return <IdVerification />;
  }

  if (allLoadingDone && !user?.permissions?.includes("app_user.view_only") && !rider.vehicle) {
    return <VehicleSelect />;
  }

  if (
      allLoadingDone &&
      !user?.permissions?.includes("app_user.view_only") &&
    (!gears || gears.length === 0) &&
    !rider.onboardingSkipped
  ) {
    if (
      rider.status === RiderStatus.INACTIVE ||
      rider.status === RiderStatus.CLEARANCE
    ) {
      return null;
    }
    return <AssignGear rider={rider} finnishAssign={finnishKitAssign} />;
  }

  if (allLoadingDone && !user?.permissions?.includes("app_user.view_only") && assignMissing) {
    return (
      <>
        <AssignGear
          rider={rider}
          finnishAssign={finnishKitAssign}
          missingProductsKit={missingKitProducts}
          skipedAllProducts={skipedAllProducts}
        />
        <div className="flex flex-col items-center mt-6">
          <button
            onClick={() => {
              setAssignMissing(false);
            }}
            className="button"
          >
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </button>
        </div>
      </>
    );
  }

  if (allLoadingDone && !user?.permissions?.includes("app_user.view_only") && assignNewGearItem) {
    return (
      <AssignNewGearItem
        setAssignNewGearItem={setAssignNewGearItem}
        fetchGears={fetchGears}
        riderGears={gears}
      />
    );
  }

  if (allLoadingDone){
    return (
            <>
              <div className="flex flex-col justify-center items-center">
                <div className="w-80">
                  <p className="text-white text-center text-large">{t(subTitle)}</p>
                  <TabView tabs={tabs} showTabsNavbar={showTabsNavbar} />
                </div>
              </div>
            </>
    );
  }

  return null;

};

export default RiderProfile;
