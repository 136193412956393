import React, { useState } from "react";
import { KitProductModel } from "../../../Api/Models/product.model";
import ProductWithSizes from "./ProductWithSizes";
import { useTranslation } from "react-i18next";

interface ProductGroupProps {
  kitProducts: KitProductModel[];
  setKitProductGroupChoice(
    groupId: number,
    kitProductId: number,
    selectedVariationId: number | null
  ): void;
  skipProduct: CallableFunction;
  skipAll: CallableFunction;
  back: CallableFunction | null;
}

const ProductGroup: React.FunctionComponent<ProductGroupProps> = ({
  kitProducts,
  setKitProductGroupChoice,
  skipProduct,
  skipAll,
  back,
}) => {
  const { t } = useTranslation();

  const [selectedVariationalProduct, setSelectedVariationalProduct] =
    useState<KitProductModel | null>(null);

  const selectProduct = (productId: number) => {
    let product = kitProducts.find((x) => x.id === productId);
    if (product?.variations && product.variations.length > 0) {
      setSelectedVariationalProduct(product);
      return;
    }

    if (product?.optionalGroupId) {
      setKitProductGroupChoice(product.optionalGroupId, productId, null);
    }
  };

  const setKitProductVariation = (
    kitProductId: number,
    selectedVariationId: number
  ) => {
    if (kitProducts[0]?.optionalGroupId) {
      setKitProductGroupChoice(
        kitProducts[0].optionalGroupId,
        kitProductId,
        selectedVariationId
      );
      setSelectedVariationalProduct(null);
    }
  };

  const unselectVariationalProduct = () => {
    setSelectedVariationalProduct(null);
  };

  if (selectedVariationalProduct) {
    return (
      <ProductWithSizes
        kitProduct={selectedVariationalProduct}
        setKitProductVariation={setKitProductVariation}
        handleBack={unselectVariationalProduct}
        skipProduct={skipProduct}
        skipAll={skipAll}
        back={() => setSelectedVariationalProduct(null)}
      />
    );
  }

  return (
    <div>
      <div
        className="white-divider"
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
      ></div>

      <div style={{ margin: "0 10%" }}>
        {kitProducts.map((product, index) => (
          <React.Fragment key={product.id}>
            <div
              className="kit-product-header"
              onClick={() => selectProduct(product.id)}
            >
              <img src={product.image} alt={product.name} width="70px" />
              <div className="product-name-wrapper">
                <span>{product.name}</span>
              </div>
            </div>
            {index !== kitProducts.length - 1 && (
              <p className="text-center text-white my-3 h2">Or</p>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex flex-col items-center mt-4">
        <div>
          <button
            type="button"
            className="button mr-3"
            onClick={() => skipProduct(kitProducts[0].id)}
          >
            {t("Skip")}
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center mt-4">
        <div>
          {back ? (
            <button
              type="button"
              className="button mr-3"
              onClick={() => back()}
            >
              {t("< Back")}
            </button>
          ) : (
            <button
              type="button"
              className="button mr-3"
              onClick={() => skipAll()}
            >
              {t("Skip all")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductGroup;
