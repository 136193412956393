import request, { Method } from "../request";

class WeeklyStockCheckService {
  async thisWeekComplete() {
    return request<{ complete: boolean }>(
      `/weekly-stock-check/this-week-complete`,
      Method.GET
    );
  }

  async thisWeekCheckedProducts() {
    return request<{ productIds: number[] }>(
      "/weekly-stock-check/this-week-checked-products",
      Method.GET
    );
  }

  async store(productsIds: number[]) {
    return request(`/weekly-stock-check/store`, Method.POST, {
      data: {
        products: productsIds,
      },
    });
  }
}

export const weeklyStockCheckService = new WeeklyStockCheckService();
