import React, { useState } from "react";
import { KitProductModel } from "../../../Api/Models/product.model";
import ImageInput from "../../../Components/ImageInput";
import { useTranslation } from "react-i18next";
import clockIcon from "../../../Assets/gfx/icons/clock.svg";

interface ConfrimationScreenProps {
  kitProducts: KitProductModel[];
  uncheckProduct(productId: number): void;
  setItemPrepared(productId: number): void;
  assignKit(files: File[]): void;
  back: CallableFunction;
}

const EditIcon: React.FunctionComponent<{}> = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width="20px"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
      />
    </svg>
  );
};

const ConfrimationScreen: React.FunctionComponent<ConfrimationScreenProps> = ({
  kitProducts,
  uncheckProduct,
  setItemPrepared,
  assignKit,
  back,
}) => {
  const [images, setImages] = useState<File[]>([]);
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const editProduct = (productId: number) => {
    uncheckProduct(productId);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Disable the button
    setButtonDisabled(true);

    // Enable the button after 300ms
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);
    assignKit(images);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="white-divider"
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
      ></div>

      <p className="text-white text-center text-large mb-3">
        {t("Confirm items")}
      </p>

      <div style={{ margin: "0 10%" }} className="flex flex-col">
        {kitProducts
          .filter(
            (x) =>
              x.selected || (!x.stockEnough && !x.optionalGroupId) || x.skipped
          )
          .map((product) => (
            <div key={product.id} className="flex mb-3">
              <div className="kit-product-header w-80">
                <img src={product.image} alt={product.name} width="40px" />
                <div className="product-name-wrapper">
                  {Array.from({ length: product.quantity }).map((_, i) => (
                    <React.Fragment key={i}>
                      <span>{product.name}</span>
                      {product.selectedVariationId && (
                        <span className="text-blue">
                          &nbsp;-&nbsp;
                          {
                            product.variations.find(
                              (x) => x.id === product.selectedVariationId
                            )?.name
                          }
                        </span>
                      )}
                      {i !== product.quantity - 1 ? <br /> : null}
                    </React.Fragment>
                  ))}
                </div>
                {(product.variations.length > 0 || product.optionalGroupId) && (
                  <span onClick={() => editProduct(product.id)}>
                    <EditIcon />
                  </span>
                )}
              </div>
              <div className="w-20 flex justify-center items-center">
                {product.stockEnough && !product.skipped ? (
                  <button
                    type="button"
                    className={
                      product.prepared
                        ? "button-product-status-returned"
                        : "button-product-status-default"
                    }
                    onClick={() => setItemPrepared(product.id)}
                  >
                    <i
                      className={
                        "icon-approve " +
                        (!product.prepared ? "text-green" : "")
                      }
                    ></i>
                  </button>
                ) : (
                  <img src={clockIcon} alt="Unavailable" width="50px" />
                )}
              </div>
            </div>
          ))}

        <ImageInput
          images={images}
          setImages={setImages}
          required={kitProducts.some((x) => x.stockEnough && !x.skipped)}
        />

        <button
          type="submit"
          className={
            "button " +
            (kitProducts.some((x) => x.selected && !x.prepared)
              ? "button--grey"
              : "button--blue")
          }
          disabled={buttonDisabled || kitProducts.some((x) => x.selected && !x.prepared)}
        >
          {t("Assign Item(s)")}
        </button>
        <div className="flex flex-col items-center mt-4">
          <div>
            <button
              type="button"
              className="button mr-3"
              onClick={() => back()}
            >
              {t("< Back")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ConfrimationScreen;
