import { useTranslation } from "react-i18next";
import { KitProductModel } from "../../../Api/Models/product.model";
import clockIcon from "../../../Assets/gfx/icons/clock.svg";

interface RemainingProductsProps {
  kitProducts: KitProductModel[];
  setItemChecked(productId: number): void;
  skipRemainingProducts: CallableFunction;
  back: CallableFunction;
}

const RemainingProducts: React.FunctionComponent<RemainingProductsProps> = ({
  kitProducts,
  setItemChecked,
  skipRemainingProducts,
  back,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className="white-divider"
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
      ></div>

      <div style={{ margin: "0 10%" }} className="flex flex-col">
        {kitProducts.map((product) => (
          <div key={product.id} className="flex mb-3">
            <div className="kit-product-header w-80">
              <img src={product.image} alt={product.name} width="70px" />
              <div className="product-name-wrapper">
                <span>
                  {product.quantity > 1 ? `${product.quantity} x ` : null}
                  {product.name}
                </span>
              </div>
            </div>
            <div className="w-20 flex justify-center items-center">
              {product.stockEnough ? (
                <button
                  type="button"
                  className={
                    product.checked
                      ? "button-product-status-returned"
                      : "button-product-status-default"
                  }
                  onClick={() => setItemChecked(product.id)}
                >
                  {product.checked ? (
                    <i className="icon-approve"></i>
                  ) : (
                    <i className="icon-add text-green"></i>
                  )}
                </button>
              ) : (
                <img src={clockIcon} alt="Unavailable" width="50px" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center">
        <div>
          <button
            type="submit"
            className="button button--green"
            onClick={() => skipRemainingProducts()}
          >
            {t("Next")}
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center mt-4">
        <div>
          <button type="submit" className="button" onClick={() => back()}>
            {t("< Back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemainingProducts;
