import { GoalModel } from "../Models/goal.model";
import { ReturnModel } from "../Models/return.model";
import request, { Method } from "../request";

class ReturnService {
  async getGoal() {
    return request<GoalModel>(`/return/goal`, Method.GET);
  }

  async getReturn(returnId: number) {
    return request<ReturnModel>(`/return/${returnId}`, Method.GET);
  }

  async listReturns() {
    return request<ReturnModel[]>(`/return/list`, Method.GET);
  }

  async getReturnDataFromQr(data: string) {
    return request<ReturnModel>(`/qr/return`, Method.POST, {
      data: {
        data: data,
      },
    });
  }

  async update(formData: FormData) {
    return request<ReturnModel>(`/return/update`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const returnService = new ReturnService();
