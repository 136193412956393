import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import React, { useState } from "react";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";
import Car from "../../Assets/gfx/icons/vehicles/car.png";
import Motor from "../../Assets/gfx/icons/vehicles/motorcycle.png";
//import Police from "../../Assets/gfx/icons/vehicles/police.png";

interface VehicleSelectProps {}

const VehicleSelect: React.FunctionComponent<VehicleSelectProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const { rider, setRider } =
    useOutletContext<RiderProfileLayoutContextInterface>();

  const [selectedVehicle, setSelectedVehicle] = useState<number | null>(null);

  const vehicles = [
    /*{
      id: 1,
      icon: Police,
      name: t("T-Patroller"),
    },*/
    {
      id: 2,
      icon: Motor,
      name: t("Motorbike"),
    },
    {
      id: 3,
      icon: Car,
      name: t("Car"),
    },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedVehicle) {
      return;
    }

    startLoading("update-rider-profile");
    riderService
      .update(rider.riderId, rider.idVerified, null, selectedVehicle, null)
      .then((rider) => {
        setRider(rider);
      })
      .finally(() => {
        finnishLoading("update-rider-profile");
      });
  };
  return (
    <>
      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <p className="text-white text-center text-large">
          {t("Please Request Rider Vehicle")}
        </p>
        <div className="flex flex-col justify-end mt-4 mb-4 w-80">
          {vehicles.map((vehicle) => (
            <React.Fragment key={vehicle.id}>
              <label className="radio-classic mb-4">
                <input
                  type="radio"
                  name="size"
                  checked={vehicle.id === selectedVehicle}
                  onChange={(e) => setSelectedVehicle(vehicle.id)}
                  value={vehicle.id}
                />
                <div className="radio-classic__success-icon"></div>
                <div className="radio-classic__text text-white">
                  <img
                    src={vehicle.icon}
                    alt="Vehicle"
                    style={{
                      width: "30px",
                      display: "inline",
                      marginRight: "10px",
                      filter:
                        "invert(89%) sepia(80%) saturate(2585%) hue-rotate(13deg) brightness(102%) contrast(95%)",
                    }}
                  />
                  {vehicle.name}
                </div>
              </label>
            </React.Fragment>
          ))}
        </div>
        <div className="flex flex-col items-center">
          <button type="submit" className="button button--green mb-4">
            {t("Save")}
          </button>
          <Link to="/rider/search" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </form>
    </>
  );
};

export default VehicleSelect;
