import request, { Method } from "../request";

class PushService {
  async subscribe(payload: PushSubscription) {
    return request(`/push/subscribe`, Method.POST, {
      data: payload,
    });
  }
}

export const pushService = new PushService();
