import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

interface InfoModalProviderProps {
  children: JSX.Element;
}

interface InfoModalContextInterface {
  showInfo: CallableFunction;
}

const InfoModalContext = createContext<InfoModalContextInterface>({
  showInfo: () => {},
});

export const InfoModalProvider = ({ children }: InfoModalProviderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const { t } = useTranslation();

  const showInfo = (infoMessage: string) => {
    setMessage(infoMessage);
    setOpen(true);
  };

  return (
    <InfoModalContext.Provider value={{ showInfo }}>
      {open && (
        <div
          className={"info-modal modal" + (!open ? "hidden" : "")}
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal__dialog">
            <div className="modal__body">
              <div className="pt-4 text-center">{message}</div>
            </div>
            <div className="modal__footer">
              <div className="flex justify-center">
                <button
                  className="button button--green"
                  onClick={() => setOpen(false)}
                >
                  {t("Ok")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </InfoModalContext.Provider>
  );
};

export default InfoModalProvider;

export function useInfoModal() {
  return useContext(InfoModalContext);
}
