import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductModel } from "../../Api/Models/product.model";
import { riderService } from "../../Api/Services/RiderService";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { useOutletContext } from "react-router-dom";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { RiderProductModel } from "../../Api/Models/rider.model";

interface AssignNewGearItemProps {
  setAssignNewGearItem: CallableFunction;
  fetchGears: CallableFunction;
  riderGears: RiderProductModel[];
}

const AssignNewGearItem: React.FunctionComponent<AssignNewGearItemProps> = ({
                                                                              setAssignNewGearItem,
                                                                              fetchGears,
                                                                              riderGears,
                                                                            }) => {
  const { t } = useTranslation();
  const { showInfo } = useInfoModal();
  const { rider } = useOutletContext<RiderProfileLayoutContextInterface>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [results, setResults] = useState<ProductModel[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
      null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const quantityRef = useRef<HTMLInputElement | null>(null);
  const dateRef = useRef<HTMLInputElement | null>(null);
  const variationRef = useRef<HTMLSelectElement | null>(null);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lazyLoadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let callback: CallableFunction | null = null;

    callback = () => {
      searchProducts(searchTerm, page);
    };

    timeoutRef.current = setTimeout(() => (callback ? callback() : null), 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    //eslint-disable-next-line
  }, [searchTerm, page]);

  useEffect(() => {
    setPage(0);
  }, [searchTerm]);

  useEffect(() => {
    const handleScroll = () => {
      if (lazyLoadingTimeoutRef.current) {
        clearTimeout(lazyLoadingTimeoutRef.current);
      }

      lazyLoadingTimeoutRef.current = setTimeout(() => {
        if (
            window.scrollY + window.innerHeight >=
            document.documentElement.scrollHeight
        ) {
          setPage((prev) => prev + 1);
        }
      }, 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (lazyLoadingTimeoutRef.current) {
        clearTimeout(lazyLoadingTimeoutRef.current);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    searchProducts("");
    //eslint-disable-next-line
  }, []);

  const searchProducts = async (q: string, p: number = 0) => {
    setLoading(true);
    riderService
        .searchProducts(rider.riderId, q, p)
        .then((products) => {
          console.log(products, p);

          if (p === 0) {
            setResults(products);
          } else {
            setResults((prev) =>
                [...prev, ...products].filter(
                    (value, index, self) => self.indexOf(value) === index
                )
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const handleAssignItem = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProductId || !dateRef.current || !quantityRef.current) {
      return;
    }

    setLoading(true);
    riderService
        .assignGearItem(
            rider.riderId,
            selectedProductId,
            variationRef?.current?.value
                ? parseInt(variationRef.current.value)
                : null,
            dateRef.current.value,
            parseInt(quantityRef.current.value)
        )
        .then(() => {
          fetchGears();
          showInfo(t("Item successfully assigned!"));
          setAssignNewGearItem(null);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
      <>
        <div
            className={"info-modal modal " + (!selectedProductId ? "hidden" : "")}
            tabIndex={-1}
            role="dialog"
        >
          <div className="modal__dialog">
            <div className="modal__header">
              <div className="modal__heading">{t("Assign new Gear")}</div>
              <div
                  className="modal__close"
                  onClick={() => setSelectedProductId(null)}
              >
                ✖
              </div>
            </div>
            <form className="modal__body" onSubmit={handleAssignItem}>
              <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "start",
                    marginBottom: "10px",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
              >
                <img
                    src={
                        results.find((x) => x.id === selectedProductId)?.image ?? ""
                    }
                    alt="Product"
                    style={{ height: "75px" }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <strong>
                  <span>
                    {results.find((x) => x.id === selectedProductId)?.name ??
                        ""}
                  </span>
                  </strong>
                </div>
              </div>

              <label>{t("Quantity")}</label>
              <input
                  type="number"
                  step={1}
                  min={1}
                  max={
                      (results?.find((x) => x.id === selectedProductId)
                          ?.maxPerRider ?? 20) -
                      riderGears?.filter((x) => x.productId === selectedProductId)
                          ?.length
                  }
                  ref={quantityRef}
                  defaultValue={1}
                  placeholder={t("Quantity") ?? ""}
                  className="input-field bg-lightgray mb-2"
                  required
              />

              <label>{t("Date")}</label>
              <input
                  type="date"
                  ref={dateRef}
                  placeholder={t("Date") ?? ""}
                  className="input-field bg-lightgray mb-2"
                  required
                  defaultValue={rider.joinDate}
              />

              {results.find((x) => x.id === selectedProductId)?.variations
                  .length ? (
                  <>
                    <label>{t("Size")}</label>
                    <select
                        className="input-field bg-lightgray mb-2"
                        ref={variationRef}
                        required
                    >
                      <option value="">-</option>
                      {results
                          .find((x) => x.id === selectedProductId)
                          ?.variations.map((variation) => (
                              <option key={variation.id} value={variation.id ?? ""}>
                                {variation.name}
                              </option>
                          ))}
                    </select>
                  </>
              ) : null}

              <div className="flex justify-center mb-8 mt-5">
                <button type="submit" className="button button--green">
                  {t("Save")}
                </button>
              </div>
            </form>
          </div>
        </div>

        <p className="text-white text-center text-large mb-2">
          <b>{t("Assign new Gear")}</b>
        </p>

        <div className="flex flex-col items-center">
          <input
              className="input-field text-blue w-60 see-through-background mb-2"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t("Gear name") ?? ""}
          />

          <div
              className="flex flex-wrap"
              style={{ width: "100%", position: "relative", minHeight: "100px" }}
          >
            {loading && (
                <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0,0,0,.3)",
                      zIndex: 99999999,
                      borderRadius: "10px",
                    }}
                >
                  <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                  >
                    <div className="loader mx-auto">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
            )}
              {results
                  .filter((product) => {
                      const maxAssignable =
                          (product.maxPerRider ?? 20) -
                          riderGears?.filter((x) => x.productId === product.id)?.length;
                      return maxAssignable > 0; // Csak azok jelenjenek meg, ahol még kiosztható mennyiség van.
                  })
                  .map((product) => {

                      return (
                          <div
                              style={{
                                  padding: "5px",
                                  flexGrow: 0,
                                  width: "50%",
                              }}
                              key={product.id}
                          >
                              <div className="card flex flex-col" style={{ height: "100%" }}>
                                  <div
                                      className="flex"
                                      style={{
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                      }}
                                  >
                                      <Gallery>
                                          <Item original={product.image ?? ""}>
                                              {({ ref, open }) => (
                                                  <img
                                                      onClick={open}
                                                      src={product.image ?? ""}
                                                      alt={product.name}
                                                      style={{
                                                          marginRight: "10px",
                                                          width: "60px",
                                                          height: "60px",
                                                          objectFit: "contain",
                                                      }}
                                                  />
                                              )}
                                          </Item>
                                      </Gallery>

                                      <button
                                          className="button-add"
                                          onClick={() => setSelectedProductId(product.id)}
                                      >
                                          <i className="icon-add"></i>
                                      </button>
                                  </div>
                                  <span className="text-center">{product.name}</span>
                              </div>
                          </div>
                      );
                  })}
          </div>

          <div className="flex justify-center mt-6  ">
            <button
                className="button"
                type="button"
                onClick={() => setAssignNewGearItem(null)}
            >
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </button>
          </div>
        </div>
      </>
  );
};

export default AssignNewGearItem;