import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import ImageInput from "../../Components/ImageInput";
import { useEffect, useRef, useState } from "react";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";

interface IdVerificationProps {}

const IdVerification: React.FunctionComponent<IdVerificationProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const { setPageTitle, rider, setRider } =
    useOutletContext<RiderProfileLayoutContextInterface>();

  useEffect(() => {
    setPageTitle(t("Request ID Verification"));
  }, [t, setPageTitle]);

  const idVerifiedRef = useRef<HTMLInputElement | null>(null);
  const [images, setImages] = useState<File[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!idVerifiedRef.current) {
      return;
    }
    startLoading("update-rider-profile");
    riderService
      .update(
        rider.riderId,
        idVerifiedRef.current?.checked,
        null,
        rider.vehicle,
        images[0] ?? null
      )
      .then((rider) => {
        setRider(rider);
      })
      .finally(() => {
        finnishLoading("update-rider-profile");
      });
  };
  return (
    <>
      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <label className="checkbox mb-4">
          <input
            type="checkbox"
            ref={idVerifiedRef}
            required
            defaultChecked={rider.idVerified}
          />
          <div className="checkbox__success-icon no-border see-through-background"></div>
          <div className="checkbox__text text-white">{t("ID Verified")}</div>
        </label>
        <br />
        <span className="text-white text-small mb-2">{t("Optional")}</span>
        <ImageInput
          images={images}
          setImages={setImages}
          title={t("Take Rider Picture")}
          multiple={false}
          required={false}
        />
        <div className="flex flex-col items-center">
          <button type="submit" className="button button--green mb-4">
            {t("Save")}
          </button>
          <Link to="/rider/search" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </form>
    </>
  );
};

export default IdVerification;
