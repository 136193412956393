import { useLocation } from "react-router-dom";
import { TabType } from "../Types/Tab.type";
import { useState } from "react";

interface TabViewProps {
  tabs: TabType[];
  showTabsNavbar: boolean;
}

const TabView: React.FunctionComponent<TabViewProps> = ({
  tabs,
  showTabsNavbar = true,
}) => {
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>(
    state?.tab ?? tabs[0].key
  );

  return (
    <div className="tab-view">
      <div className="tab-view__content py-2">
        {tabs.find((item) => item.key === selectedTab)?.element ?? <p></p>}
      </div>
      {showTabsNavbar && (
        <div className="tab-view__navbar">
          {tabs.map((item, index) => (
            <div
              key={index}
              className={item.key === selectedTab ? "active" : ""}
              onClick={() => {
                setSelectedTab(item.key);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TabView;
