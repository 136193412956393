import { useState } from "react";
import { RiderProductModel } from "../../Api/Models/rider.model";
import { TshirtItem } from "./Exchange";
import { ProductModel } from "../../Api/Models/product.model";
import TshirtIcon from "../../Assets/gfx/tshirt.jpg";
import { t } from "i18next";
import { StockModel } from "../../Api/Models/stock.model";
import exchangeIcon from "../../Assets/gfx/icons/exchange.svg";

interface ExchangeTshirtsProps {
  gears: RiderProductModel[];
  returnedTshirts: TshirtItem[];
  setReturnedTshirts: CallableFunction;
  dispatchedTshirts: TshirtItem[];
  setDispatchedTshirts: CallableFunction;
  stocks: StockModel[];
  maxTshirtsCount: { maxTshirts: number; returnModifier: number };
  tShirts: ProductModel[];
  setTShirts: React.Dispatch<React.SetStateAction<ProductModel[]>>;
}

const ExchangeTshirts: React.FunctionComponent<ExchangeTshirtsProps> = ({
  gears,
  returnedTshirts,
  dispatchedTshirts,
  setReturnedTshirts,
  setDispatchedTshirts,
  stocks,
  maxTshirtsCount,
  tShirts,
  setTShirts,
}) => {
  const [showTshirtExchange, setShowTshirtExchange] = useState<boolean>(false);

  const modifyReturnedTshirts = (
    productId: number,
    variationId: number,
    increase: boolean
  ) => {
    if (
      increase &&
      gears.filter(
        (item) =>
          item.productId === productId &&
          item.productVariationId === variationId
      ).length <=
        (returnedTshirts.find(
          (item) =>
            item.productId === productId && item.variationId === variationId
        )?.quantity ?? 0)
    ) {
      return;
    }

    if (!increase) {
      let dispatchedSum = dispatchedTshirts.reduce((accumulator, object) => {
        return accumulator + object.quantity;
      }, 0);

      let returnedSum = returnedTshirts.reduce((accumulator, object) => {
        return accumulator + object.quantity;
      }, 0);

      if (
        dispatchedSum > maxTshirtsCount.maxTshirts &&
        returnedSum - 1 < dispatchedSum - maxTshirtsCount.maxTshirts
      ) {
        return;
      }
    }

    let state = [...returnedTshirts];

    if (
      !state.find(
        (item) =>
          item.productId === productId && item.variationId === variationId
      )
    ) {
      state.push({
        productId,
        variationId,
        quantity: increase ? 1 : 0,
      });
    } else {
      state = state.map((item) =>
        item.productId === productId && item.variationId === variationId
          ? {
              productId,
              variationId,
              quantity: increase
                ? item.quantity + 1
                : Math.max(item.quantity - 1, 0),
            }
          : item
      );
    }

    setReturnedTshirts(state.filter((item) => item.quantity > 0));
  };

  const modifyDispatchedTshirts = (
    productId: number,
    variationId: number,
    increase: boolean
  ) => {
    let localDispatchedTshirts = [...dispatchedTshirts];

    if (localDispatchedTshirts.find((x) => x.productId !== productId)) {
      localDispatchedTshirts = [];
    }

    const baseQuantity = localDispatchedTshirts.find((x) => x.productId === productId)?.quantity ?? 0;

    localDispatchedTshirts = localDispatchedTshirts.filter((x) => x.productId !== productId);

    let returnedSum = returnedTshirts.reduce((accumulator, object) => {
      return accumulator + object.quantity;
    }, 0);

    let returnModifier = 0;

    if (returnedSum >= maxTshirtsCount.returnModifier) {
      returnModifier = maxTshirtsCount.returnModifier;
    } else {
      returnModifier = returnedSum;
    }

    if (
      increase &&
        localDispatchedTshirts.reduce((accumulator, object) => {
        return accumulator + object.quantity;
      }, 0) >=
        maxTshirtsCount.maxTshirts + returnModifier
    ) {
      return;
    }

    if (
      !localDispatchedTshirts.find(
        (item) =>
          item.productId === productId && item.variationId === variationId
      )
    ) {
      localDispatchedTshirts.push({
        productId,
        variationId,
        quantity: Math.min(maxTshirtsCount.maxTshirts + returnModifier, Math.max(baseQuantity + (increase ? 1 : -1), 0)),
      });
    } else {
      localDispatchedTshirts = localDispatchedTshirts.map((item) =>
        item.productId === productId && item.variationId === variationId
          ? {
              productId,
              variationId,
              quantity: increase
                ? item.quantity + 1
                : Math.max(item.quantity - 1, 0),
            }
          : item
      );
    }

    setDispatchedTshirts(localDispatchedTshirts.filter((item) => item.quantity > 0));
  };

  return (
    <>
      <div
        className={"modal" + (!showTshirtExchange ? " hidden" : "")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog modal-dialog--full-height">
          <div className="modal__header">
            <div className="modal__heading">{t("Item detailes")}</div>
          </div>
          <div className="modal__body">
            <div className="stock-list-card">
              <div className="stock-list-card__image mr-3">
                <img
                  src={TshirtIcon}
                  style={{
                    height: "75px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                  alt="Product"
                />
              </div>
              <div className="stock-list-card__content">
                <div className="stock-list-card__title">
                  {t("T-Shirt exchange")}
                </div>
                <p className="mb-3">{t("Returned Items")}</p>

                <div className="stock-list-card__info">
                  {gears
                    .filter((x) => x.isTshirt)
                    .filter(
                      (x, index, array) =>
                        array.findIndex(
                          (i) =>
                            i.productId === x.productId &&
                            i.productVariationId === x.productVariationId
                        ) === index
                    )
                    .sort((a, b) =>
                      a.productId !== b.productId
                        ? a.productId - b.productId
                        : (a.productVariationId ?? 0) -
                          (b.productVariationId ?? 0)
                    )
                    .map((gear, index, array) => (
                      <div
                        key={gear.id}
                        className="flex justify-between items-center mb-1"
                      >
                        <div className="flex justify-between">
                          <span className="text-bold">
                            {(array[index - 1]?.productId ?? -1) !==
                            gear.productId ? (
                              <strong>{gear.productName}</strong>
                            ) : (
                              <>&nbsp;</>
                            )}
                          </span>
                          <span>&nbsp;{gear.variationName}</span>
                        </div>
                        <div className="ml-6">
                          <div className="order-product-variation-quantity">
                            <button
                              type="button"
                              className="order-product-variation-quantity__decrement"
                              onClick={() => {
                                if (gear.productVariationId) {
                                  modifyReturnedTshirts(
                                    gear.productId,
                                    gear.productVariationId,
                                    false
                                  );
                                }
                              }}
                            ></button>
                            <input
                              className="order-product-variation-quantity__input"
                              type="number"
                              value={
                                returnedTshirts.find(
                                  (item) =>
                                    item.productId === gear.productId &&
                                    item.variationId === gear.productVariationId
                                )?.quantity ?? 0
                              }
                              disabled={true}
                            />
                            <button
                              type="button"
                              className="order-product-variation-quantity__increment"
                              onClick={() => {
                                if (gear.productVariationId) {
                                  modifyReturnedTshirts(
                                    gear.productId,
                                    gear.productVariationId,
                                    true
                                  );
                                }
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <hr className="mt-4 mb-3" />

                <p className="mb-3">{t("Distributed Items")}</p>
                {tShirts.map((tshirt) => (
                  <div
                    key={`${tshirt.id}-${tshirt.selectedVariationId}`}
                    className="flex"
                  >
                    <div className="flex flex-col" style={{ flexGrow: 1 }}>
                      <strong className="mr-2">{tshirt.name}</strong>
                      <div className="flex items-center mb-1 justify-between">
                        <select
                          onChange={(e) => {
                            let oldQuantity = dispatchedTshirts.find(
                                (item) =>
                                    item.productId === tshirt.id
                            )?.quantity ?? 0;

                            setTShirts((prev) => [
                              ...prev.map((item) => {
                                if (item.id === tshirt.id) {
                                  item.selectedVariationId = parseInt(
                                    e.target.value
                                  );
                                } else {
                                  item.selectedVariationId = null;
                                }

                                return item;
                              }),
                            ]);

                            setTimeout(() => {
                                console.log(oldQuantity)
                                for (let i = 0; i < oldQuantity; i++) {
                                    modifyDispatchedTshirts(
                                        tshirt.id,
                                        parseInt(e.target.value),
                                        true
                                    );
                                    console.log('dispatched', dispatchedTshirts, oldQuantity, i, tshirt.id, parseInt(e.target.value))
                                }
                            }, 500);
                          }}
                          defaultValue={
                            tshirt.selectedVariationId?.toString() ?? undefined
                          }
                        >
                          <option value="">-</option>
                          {tshirt.variations
                            .filter(
                              (x) =>
                                (stocks.find(
                                  (stock) => stock.variationId === x.id
                                )?.quantity ?? -1) > 0
                            )
                            .map((variation) => (
                              <option key={variation.id} value={variation.id}>
                                {variation.name}
                              </option>
                            ))}
                        </select>
                        <div className="ml-6">
                          <div className="order-product-variation-quantity">
                            <button
                              type="button"
                              className="order-product-variation-quantity__decrement"
                              onClick={() => {
                                if (tshirt.selectedVariationId) {
                                  modifyDispatchedTshirts(
                                    tshirt.id,
                                    tshirt.selectedVariationId,
                                    false
                                  );
                                }
                              }}
                            ></button>
                            <input
                              className="order-product-variation-quantity__input"
                              type="number"
                              value={
                                dispatchedTshirts.find(
                                  (item) =>
                                    item.productId === tshirt.id &&
                                    item.variationId ===
                                      tshirt.selectedVariationId
                                )?.quantity ?? 0
                              }
                              disabled={true}
                            />
                            <button
                              type="button"
                              className="order-product-variation-quantity__increment"
                              onClick={() => {
                                if (tshirt.selectedVariationId) {
                                  modifyDispatchedTshirts(
                                    tshirt.id,
                                    tshirt.selectedVariationId,
                                    true
                                  );
                                }
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div>
                <button
                  type="button"
                  className="button mr-3"
                  onClick={() => {
                    setReturnedTshirts([]);
                    setDispatchedTshirts([]);
                    setShowTshirtExchange(false);
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="button"
                  className="button button--green"
                  onClick={() => {
                    setShowTshirtExchange(false);
                  }}
                >
                  {t("Ok")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {maxTshirtsCount.maxTshirts + maxTshirtsCount.returnModifier > 0 ? (
        <div className="exchange-list__item">
          <div className="gap-4px nav-card mb-4">
            <img src={TshirtIcon} alt="package name" />
            <p>
              <strong>{t("T-Shirts")}</strong>
            </p>
          </div>
          <span>
            <div className={"status open"}>
              {returnedTshirts.length > 0 || dispatchedTshirts.length > 0 ? (
                <button
                  type="button"
                  className="button-product-status-returned"
                  onClick={() => setShowTshirtExchange(true)}
                >
                  <i className="icon-approve"></i>
                </button>
              ) : (
                <img
                  src={exchangeIcon}
                  alt="Exchange img"
                  onClick={() => setShowTshirtExchange(true)}
                />
              )}
            </div>
          </span>
        </div>
      ) : null}
    </>
  );
};

export default ExchangeTshirts;
