export enum ReturnStatus {
  UNKNOWN = 1,
  GOOD = 2,
  MISSING = 3,
  UNUSABLE = 4,
  REUSABLE = 5,
}

export enum ReturnCondition {
  OVERUSED = 1,
  BROKEN = 2,
  STAIN = 3,
  MANUFACTURING_FAULT = 4,
  LOST = 5,
  ACCIDENT = 6,
}
export interface ReturnItemModel {
  id: number;
  status: ReturnStatus;
  condition: ReturnCondition;
  quantity: number;
  productName: string;
  productImage: string;
  sanitizePossible: boolean;
  note?: string | null;
  uploadImages?: FileList;
  savedToDb: boolean;
  originalAssignDate: string;
  reusePossible: boolean;
}

export interface ReturnModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  riderId: string;
  date: string;
  items: ReturnItemModel[];
}
