import { useTranslation } from "react-i18next";
import { KitProductModel } from "../../../Api/Models/product.model";
import { useRef } from "react";

export const ExchangeIcon: React.FunctionComponent<{}> = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width="20px"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
};

interface ProductWithSizesProps {
  kitProduct: KitProductModel;
  setKitProductVariation: CallableFunction;
  handleBack?: CallableFunction;
  skipProduct: CallableFunction;
  skipAll: CallableFunction;
  back: CallableFunction | null;
}

const ProductWithSizes: React.FunctionComponent<ProductWithSizesProps> = ({
  kitProduct,
  setKitProductVariation,
  handleBack,
  skipProduct,
  skipAll,
  back,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setKitProductVariation(kitProduct.id, selectedVariationId.current);
  };

  const selectedVariationId = useRef<number>();

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="white-divider"
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
      ></div>

      <p className="text-white text-large text-center mb-3">
        {t(
          "Select the Rider’s size, if it’s not available you can skip it and come back later"
        )}
      </p>

      <div className="kit-product-header">
        <img src={kitProduct.image} alt={kitProduct.name} width="70px" />
        <div className="product-name-wrapper">
          <span>
            {kitProduct.quantity > 1 ? `${kitProduct.quantity} x ` : null}
            {kitProduct.name}
          </span>
        </div>
      </div>

      {handleBack && (
        <div className="flex justify-center">
          <div
            className="kit-product-group-exchange mt-4"
            onClick={() => handleBack()}
          >
            <span className="mr-3">{t("Select an other product")}</span>
            <ExchangeIcon />
          </div>
        </div>
      )}

      <div className="flex flex-wrap justify-center mt-4 mb-3">
        {kitProduct.variations.map((variation) => (
          <div key={variation.id} className="size-radio-wrapper">
            <input
              type="radio"
              id={"radio-variation-" + variation.id}
              name="variation"
              className="size-radio-button"
              value={variation.id}
              onChange={(e) => {
                if (e.target.checked && e.target.value) {
                  selectedVariationId.current = +e.target.value;
                }
              }}
              disabled={!variation.stockEnough}
              required
            />
            <label
              htmlFor={"radio-variation-" + variation.id}
              className={
                "w-full button--blue" +
                (!variation.stockEnough ? " out-of-stock" : "")
              }
            >
              {!variation.stockEnough ? (
                <svg
                  className="mr-3"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="25px"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              ) : null}

              {variation.name}
            </label>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center">
        <div>
          <button
            type="button"
            className="button mr-3"
            onClick={() => skipProduct(kitProduct.id)}
          >
            {t("Skip")}
          </button>
          <button type="submit" className="button button--green">
            {t("Next")}
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center mt-4">
        <div>
          {back ? (
            <button
              type="button"
              className="button mr-3"
              onClick={() => back()}
            >
              {t("< Back")}
            </button>
          ) : (
            <button
              type="button"
              className="button mr-3"
              onClick={() => skipAll()}
            >
              {t("Skip all")}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ProductWithSizes;
