import { createContext, useContext, useRef, useState } from "react";

interface LoadingContextInterface {
  startLoading: CallableFunction;
  finnishLoading: CallableFunction;
}

interface LoadingProviderPropsInterface {
  children: JSX.Element;
}

const LoadingContext = createContext<LoadingContextInterface>({
  startLoading: () => {},
  finnishLoading: () => {},
});

export const LoadingProvider = ({
  children,
}: LoadingProviderPropsInterface) => {
  const [loading, setLoading] = useState<string[]>([]);
  const loadingArray = useRef<string[]>([]);

  const startLoading = (eventName: string) => {
    loadingArray.current = [...loading, eventName];
    console.log("start load", eventName);

    setLoading(loadingArray.current);
  };

  const finnishLoading = (eventName: string) => {
    loadingArray.current = loadingArray.current.filter((x) => x !== eventName);
    setLoading(loadingArray.current);
  };

  return (
    <LoadingContext.Provider value={{ startLoading, finnishLoading }}>
      {loading.length > 0 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,.4)",
            zIndex: 99999999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="loader mx-auto">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;

export function useLoading() {
  return useContext(LoadingContext);
}
