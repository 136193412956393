import { GoalModel } from "../Models/goal.model";
import { KitModel, PrepareProductModel } from "../Models/product.model";
import request, { Method } from "../request";

class KitService {
  async getGoal() {
    return request<GoalModel>(`/kits/goal`, Method.GET);
  }

  async listKits() {
    return request<KitModel[]>(`/kits/list`, Method.GET);
  }

  async getKitForRider(vehicle: number, riderId: string) {
    return request<KitModel>(
      `/kits/get-for-rider/${vehicle}/${riderId}`,
      Method.GET
    );
  }

  async listProducts() {
    return request<PrepareProductModel[]>(`/kits/products/list`, Method.GET);
  }

  async prepareKit(kit: KitModel) {
    return request(`/kits/prepare/${kit.id}`, Method.POST);
  }

  async disassembleKit(kit: KitModel) {
    return request(`/kits/disassemble/${kit.id}`, Method.POST);
  }

  async prepareProduct(prodcut: PrepareProductModel) {
    return request(`/kits/products/prepare`, Method.POST, {
      data: {
        productId: prodcut.productId,
        variationId: prodcut.variationId,
      },
    });
  }

  async disassembleProduct(prodcut: PrepareProductModel) {
    return request(`/kits/products/disassemble`, Method.POST, {
      data: {
        productId: prodcut.productId,
        variationId: prodcut.variationId,
      },
    });
  }
}

export const kitService = new KitService();
